import Loadable from '../components/Loadable/Loadable';
import type { Route } from '../types/router';

const fixedRoutes: Route[] = [
  {
    path: 'login',
    name: 'Login',
    component: Loadable({
      loader: () => import('../modules/auth/Login')
    })
  }
];

const asyncRoutes: Route[] = [
  {
    path: '',
    name: 'index',
    component: Loadable({
      loader: () => import('../modules/dashboard/Dashboard')
    }),
    meta: {
      title: 'Dashboard',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'dashboard',
    name: 'dashboard',
    component: Loadable({
      loader: () => import('../modules/dashboard/Dashboard')
    }),
    meta: {
      title: 'Dashboard',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'me',
    name: 'me',
    component: Loadable({
      loader: () => import('../modules/me/Update')
    }),
    meta: {
      title: 'Profile',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'content',
    name: 'Content',
    component: Loadable({
      loader: () => import('../modules/content/List')
    }),
    meta: {
      title: 'Content',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'content/update/:id',
    name: 'Content update',
    component: Loadable({
      loader: () => import('../modules/content/Update')
    }),
    meta: {
      title: 'Content update',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'content/create',
    name: 'Content creation',
    component: Loadable({
      loader: () => import('../modules/content/Create')
    }),
    meta: {
      title: 'Content creation',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'content-categories',
    name: 'Content categories',
    component: Loadable({
      loader: () => import('../modules/content-category/List')
    }),
    meta: {
      title: 'Content categories',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'content-categories/update/:id',
    name: 'Content category update',
    component: Loadable({
      loader: () => import('../modules/content-category/Update')
    }),
    meta: {
      title: 'Content category update',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'content-categories/create',
    name: 'Content category creation',
    component: Loadable({
      loader: () => import('../modules/content-category/Create')
    }),
    meta: {
      title: 'Content category creation',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'content-types',
    name: 'Content types',
    component: Loadable({
      loader: () => import('../modules/content-type/List')
    }),
    meta: {
      title: 'Content types',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'content-types/update/:id',
    name: 'Content type update',
    component: Loadable({
      loader: () => import('../modules/content-type/Update')
    }),
    meta: {
      title: 'Content type update',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'pages',
    name: 'Pages',
    component: Loadable({
      loader: () => import('../modules/page/List')
    }),
    meta: {
      title: 'Pages',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'pages/update/:id',
    name: 'Page update',
    component: Loadable({
      loader: () => import('../modules/page/Update')
    }),
    meta: {
      title: 'Page update',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'orders',
    name: 'orders',
    component: Loadable({
      loader: () => import('../modules/order/List')
    }),
    meta: {
      title: 'Orders',
      roles: ['admin']
    }
  },
  {
    path: 'customers',
    name: 'Customers',
    component: Loadable({
      loader: () => import('../modules/customer/List')
    }),
    meta: {
      title: 'Customers',
      roles: ['admin']
    }
  },
  {
    path: 'team-members',
    name: 'Team members',
    component: Loadable({
      loader: () => import('../modules/team-member/List')
    }),
    meta: {
      title: 'Team members',
      roles: ['admin']
    }
  },
  {
    path: 'team-members/update/:id',
    name: 'Team member update',
    component: Loadable({
      loader: () => import('../modules/team-member/Update')
    }),
    meta: {
      title: 'Team member update',
      roles: ['admin']
    }
  },
  {
    path: 'team-members/create',
    name: 'Team member creation',
    component: Loadable({
      loader: () => import('../modules/team-member/Create')
    }),
    meta: {
      title: 'Team member creation',
      roles: ['admin']
    }
  },
  {
    path: 'subscribers',
    name: 'Subscribers',
    component: Loadable({
      loader: () => import('../modules/subscriber/List')
    }),
    meta: {
      title: 'Subscribers',
      roles: ['admin']
    }
  },
  {
    path: 'tips',
    name: 'Tips',
    component: Loadable({
      loader: () => import('../modules/tip/List')
    }),
    meta: {
      title: 'Tips',
      roles: ['editor']
    }
  },
  {
    path: 'tips/update/:id',
    name: 'Tip update',
    component: Loadable({
      loader: () => import('../modules/tip/Update')
    }),
    meta: {
      title: 'Tip update',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'tips/create',
    name: 'Tip creation',
    component: Loadable({
      loader: () => import('../modules/tip/Create')
    }),
    meta: {
      title: 'Tip creation',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'conversations',
    name: 'Conversations list',
    component: Loadable({
      loader: () => import('../modules/conversation/List')
    }),
    meta: {
      title: 'Conversations',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'conversations/:id',
    name: 'Conversation detail',
    component: Loadable({
      loader: () => import('../modules/conversation/View')
    }),
    meta: {
      title: 'Conversation detail',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'feedbacks',
    name: 'Feedbacks',
    component: Loadable({
      loader: () => import('../modules/feedback/List')
    }),
    meta: {
      title: 'Feedbacks',
      roles: ['admin']
    }
  },
  {
    path: 'products',
    name: 'Products',
    component: Loadable({
      loader: () => import('../modules/product/List')
    }),
    meta: {
      title: 'Products',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'products/create',
    name: 'Product creation',
    component: Loadable({
      loader: () => import('../modules/product/Create')
    }),
    meta: {
      title: 'Product creation',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'products/update/:id',
    name: 'Product update',
    component: Loadable({
      loader: () => import('../modules/product/Update')
    }),
    meta: {
      title: 'Product update',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'bundles',
    name: 'Product Bundles',
    component: Loadable({
      loader: () => import('../modules/product/ListBundle')
    }),
    meta: {
      title: 'Products',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'bundles/create',
    name: 'Product bundle creation',
    component: Loadable({
      loader: () => import('../modules/product/CreateBundle')
    }),
    meta: {
      title: 'Product bundle creation',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'bundles/update/:id',
    name: 'Product bundle update',
    component: Loadable({
      loader: () => import('../modules/product/UpdateBundle')
    }),
    meta: {
      title: 'Product bundle update',
      roles: ['editor', 'admin']
    }
  },
  {
    path: 'categories',
    name: 'Categories',
    component: Loadable({
      loader: () => import('../modules/category/List')
    }),
    meta: {
      title: 'Categories',
      roles: ['admin']
    }
  },
  {
    path: 'categories/update/:id',
    name: 'Category update',
    component: Loadable({
      loader: () => import('../modules/category/Update')
    }),
    meta: {
      title: 'Category update',
      roles: ['admin']
    }
  },
  {
    path: 'categories/create',
    name: 'Category creation',
    component: Loadable({
      loader: () => import('../modules/category/Create')
    }),
    meta: {
      title: 'Category creation',
      roles: ['admin']
    }
  },
  {
    path: 'influencers',
    name: 'Influencers',
    component: Loadable({
      loader: () => import('../modules/influencer/List')
    }),
    meta: {
      title: 'influencer',
      roles: ['admin']
    }
  },
  {
    path: 'influencers/update/:id',
    name: 'Influencer update',
    component: Loadable({
      loader: () => import('../modules/influencer/Update')
    }),
    meta: {
      title: 'Influencer update',
      roles: ['admin']
    }
  },
  {
    path: 'influencers/create',
    name: 'Influencer creation',
    component: Loadable({
      loader: () => import('../modules/influencer/Create')
    }),
    meta: {
      title: 'Influencer creation',
      roles: ['admin']
    }
  },
  {
    path: 'invitations',
    name: 'Invitations',
    component: Loadable({
      loader: () => import('../modules/invitation/List')
    }),
    meta: {
      title: 'invitation',
      roles: ['admin']
    }
  },
  {
    path: 'invitations/update/:id',
    name: 'Invitation update',
    component: Loadable({
      loader: () => import('../modules/invitation/Update')
    }),
    meta: {
      title: 'Invitation update',
      roles: ['admin']
    }
  },
  {
    path: 'invitations/create',
    name: 'Invitation creation',
    component: Loadable({
      loader: () => import('../modules/invitation/Create')
    }),
    meta: {
      title: 'Invitation creation',
      roles: ['admin']
    }
  },
  {
    path: 'system',
    name: 'System',
    meta: {
      title: 'System',
      roles: ['admin']
    },
    children: [
      {
        path: 'users',
        name: 'Users',
        component: Loadable({
          loader: () => import('../modules/system/user/List')
        }),
        meta: {
          title: 'Users',
          roles: ['admin']
        }
      },
      {
        path: 'users/create',
        name: 'User creation',
        component: Loadable({
          loader: () => import('../modules/system/user/Create')
        }),
        meta: {
          title: 'User creation',
          roles: ['admin']
        }
      },
      {
        path: 'users/update/:id',
        name: 'User update',
        component: Loadable({
          loader: () => import('../modules/system/user/Update')
        }),
        meta: {
          title: 'User update',
          roles: ['admin']
        }
      },
      {
        path: 'files',
        name: 'Files',
        component: Loadable({
          loader: () => import('../modules/system/file/List')
        }),
        meta: {
          title: 'Files',
          roles: ['admin']
        }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Loadable({
          loader: () => import('../modules/system/setting/List')
        }),
        meta: {
          title: 'Settings',
          roles: ['admin']
        }
      },
      {
        path: 'settings/create',
        name: 'Setting creation',
        component: Loadable({
          loader: () => import('../modules/system/setting/Create')
        }),
        meta: {
          title: 'Setting creation',
          roles: ['admin']
        }
      },
      {
        path: 'settings/update/:id',
        name: 'Setting update',
        component: Loadable({
          loader: () => import('../modules/system/setting/Update')
        }),
        meta: {
          title: 'User update',
          roles: ['admin']
        }
      }
    ]
  }
];

const notFoundRoute: Route = {
  path: '*',
  name: 'NotFound',
  component: Loadable({
    loader: () => import('../modules/NotFound')
  }),
  hidden: true
};

const whiteList = fixedRoutes.map((route) => `/${route.path}`);

export { asyncRoutes, fixedRoutes, notFoundRoute, whiteList };
