import { lazy } from '@loadable/component';
import type { ComponentType } from 'react';
import React, { Suspense } from 'react';
import LoadingBox from '../LoadingBox';

interface Props {
  loader(): Promise<{ default: ComponentType<unknown> }>;
  loading?: ComponentType;
}

const Loadable = ({ loader, loading }: Props) => {
  // @ts-ignore
  const LazyComponent = lazy(loader);

  const Load = loading || LoadingBox;

  const InnerComponent = () => {
    return (
      <Suspense fallback={<Load>Loading...</Load>}>
        {/* @ts-ignore */}
        <LazyComponent />
      </Suspense>
    );
  };

  return React.memo(InnerComponent);
};

export default Loadable;
