import { version } from '../package.json';

const { VITE_API_SERVICE_URI, VITE_ENVIRONMENT, VITE_APP_URL } = import.meta.env;

export default {
  app: {
    version,
    environment: VITE_ENVIRONMENT,
    appUrl: VITE_APP_URL
  },
  api: {
    host: VITE_API_SERVICE_URI
  }
};
