import { IApiResponse } from '../lib/api';
import { bearerClient } from '../lib/http';
import { IUserInfo } from '../types/components';
import { retryPromise } from '../utils/retry-promise';

export const getMe = async () =>
  retryPromise({
    promise: async () =>
      bearerClient.get<IApiResponse<IUserInfo>>({
        apiPath: '/v1/me'
      }),
    retryCount: 5
  });
