import { create } from 'zustand';
import { IUserInfo } from '../../types/components';
import { logger } from '../middlewares/logger.middleware';

interface IAuthState {
  userInfo: IUserInfo | null;
  retrying: boolean;
  setUserInfo: (info: IUserInfo | null) => void;
  setRetrying: (retry: boolean) => void;
}

const useAuthStore = create<IAuthState>()(
  logger((set) => ({
    userInfo: null,
    retrying: false,
    setRetrying: (retry) => set(() => ({ retrying: retry })),
    setUserInfo: (info) => set(() => ({ userInfo: info }))
  }))
);

export default useAuthStore;
