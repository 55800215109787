interface LogoProps {
  width?: number;
}

function Logo({ width = 120 }: LogoProps) {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 49.2 49.2" width={width}>
      <g>
        <path
          fill="#21222B"
          d="M27.2,34.6L27.2,34.6c-3,0-5.5-2.5-5.5-5.5V14.9c0-0.2,0.1-0.3,0.3-0.3h0c3,0,5.5,2.5,5.5,5.5v14.2
		C27.6,34.4,27.4,34.6,27.2,34.6z"
        />
        <path
          fill="#21222B"
          d="M34.2,29.6h-5.2c-0.2,0-0.3-0.1-0.3-0.3v-9.3c0-0.2,0.1-0.3,0.3-0.3h0c3,0,5.5,2.5,5.5,5.5v4.2
		C34.6,29.4,34.4,29.6,34.2,29.6z"
        />
        <path
          fill="#7499A7"
          d="M20.1,29.6L20.1,29.6c-3,0-5.5-2.5-5.5-5.5v-4.2c0-0.2,0.1-0.3,0.3-0.3h5.2c0.2,0,0.3,0.1,0.3,0.3v9.3
		C20.4,29.4,20.3,29.6,20.1,29.6z"
        />
      </g>
    </svg>
  );
}

export default Logo;
