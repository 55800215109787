import { Button, Flex, Group, Stack, Text, Title } from '@mantine/core';
import classes from './fallback.module.css';

const Fallback = () => {
  return (
    <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap">
      <Stack h={300} className={classes.content}>
        <Title className={classes.title}>Something went wrong</Title>
        <Text c="dimmed" size="lg" className={classes.description}>
          Try to refresh the page
        </Text>
        <Group justify="center">
          <Button size="md" onClick={() => location.reload()}>
            Refresh the page
          </Button>
        </Group>
      </Stack>
    </Flex>
  );
};

export default Fallback;
