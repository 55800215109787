import config from '../config';
import { token } from './token';

class HttpClient {
  private readonly baseUri: string;
  private readonly hasBearer?: boolean;

  private readonly options: HttpClientOptions;

  constructor({ baseUri, options, hasBearer }: { baseUri: string; options?: HttpClientOptions; hasBearer?: boolean }) {
    this.baseUri = baseUri;
    this.options = options ?? {};
    this.hasBearer = Boolean(hasBearer);
  }

  private getRequestBody(body: unknown): { body: RequestInit['body'] } {
    if (body instanceof FormData) {
      return { body };
    }
    if (typeof body === 'object' && body !== null) {
      return { body: JSON.stringify(body) };
    }

    return { body } as { body: RequestInit['body'] };
  }

  private async request<T>(requestConfig: RequestConfig): Promise<T> {
    const { apiPath, data, method, query, headers } = requestConfig;
    const search = query ? `?${new URLSearchParams(query).toString()}` : '';
    const url = `${this.baseUri}${apiPath}${search}`;
    const ignoreHeader = data instanceof FormData;

    const reqestInit = {
      ...this.options,
      method: method.toUpperCase(),
      ...(data !== undefined && this.getRequestBody(data)),
      // to upload multiplepart file: https://stackoverflow.com/questions/36067767/how-do-i-upload-a-file-with-the-js-fetch-api
      ...(!ignoreHeader && {
        headers: {
          ...this.options.headers,
          ...(this.hasBearer && {
            Authorization: `Bearer ${token.get()?.currentSession?.session?.access_token}`
          }),
          ...headers,
          'content-type': 'application/json'
        }
      })
    };

    const response = await fetch(url, reqestInit);
    const text = await response.text();

    // make sure the response http code is between 200~299
    if (response.ok) {
      // the response body could be empty
      if (text) {
        try {
          return JSON.parse(text) as T;
        } catch {
          // the response could be a string
          return text as T;
        }
      }

      throw new Error(text);
    }

    throw new Error(text);
  }

  async delete<T>(requestConfig: Omit<RequestConfig, 'method'>): Promise<T> {
    return this.request({
      ...requestConfig,
      method: 'DELETE'
    });
  }

  async get<T>(requestConfig: Omit<RequestConfig, 'method' | 'data'>): Promise<T> {
    return this.request({
      ...requestConfig,
      method: 'GET'
    });
  }

  async patch<T>(requestConfig: UpdateRequestConfig): Promise<T> {
    return this.request({
      ...requestConfig,
      method: 'PATCH'
    });
  }

  async post<T>(requestConfig: UpdateRequestConfig): Promise<T> {
    return this.request({
      ...requestConfig,
      method: 'POST'
    });
  }

  async put<T>(requestConfig: UpdateRequestConfig): Promise<T> {
    return this.request({
      ...requestConfig,
      method: 'PUT'
    });
  }
}

export type HttpClientOptions = Pick<RequestInit, 'credentials' | 'headers' | 'mode'>;

interface RequestConfig {
  apiPath: string;
  data?: unknown;
  method: 'DELETE' | 'GET' | 'POST' | 'PATCH' | 'PUT';
  query?: Record<string, string> | string[][];
  headers?: HttpClientOptions['headers'];
}

interface UpdateRequestConfig {
  apiPath: string;
  data: unknown;
  query?: Record<string, string> | string[][];
  headers?: HttpClientOptions['headers'];
}

const httpClient = new HttpClient({
  baseUri: config.api.host
});
const httpExternalClient = new HttpClient({ baseUri: '' });

const bearerClient = new HttpClient({
  baseUri: config.api.host,
  hasBearer: true
});

export { bearerClient, httpClient, httpExternalClient };
