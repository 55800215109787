import { Route, Routes, useLocation } from 'react-router-dom';
import useRouterGuard from '../hooks/useRouterGuard';
import Layout from '../layout';

import type { ReactNode } from 'react';
import { useRouterStore } from '../store';
import type { Route as R } from '../types/router';

interface RouteProps {
  path: string;
  element?: ReactNode;
}

const renderRoutes = (routes: R[]) => {
  return routes.map(({ name, path, component: Com, children }) => {
    const property: RouteProps = {
      path
    };

    if (Com) {
      property.element = <Com />;
    }

    return (
      <Route key={name} {...property}>
        {children ? renderRoutes(children) : null}
      </Route>
    );
  });
};

const RouteTable = () => {
  const { fixedRoutes, asyncRoutes } = useRouterStore();

  const location = useLocation();

  useRouterGuard(location);

  return (
    <Routes>
      {renderRoutes(fixedRoutes)}
      <Route key="Layout" path="/" element={<Layout />}>
        {renderRoutes(asyncRoutes)}
      </Route>
    </Routes>
  );
};

export default RouteTable;
