import {
  IconAdjustmentsAlt,
  IconAppWindow,
  IconBox,
  IconDashboard,
  IconFilePencil,
  IconFriends,
  IconInfoSquare,
  IconMessage,
  IconMessageReport,
  IconReportMoney,
  IconUserPin,
  IconUsers,
  IconUsersPlus
} from '@tabler/icons-react';
import { NavbarLink } from './interface';

export const navbarLinks: NavbarLink[] = [
  {
    link: '/',
    label: 'Dashboard',
    icon: IconDashboard
  },
  {
    link: '/orders',
    label: 'Orders',
    icon: IconReportMoney,
    disableForEditor: true
  },
  {
    link: '/products',
    label: 'Products',
    icon: IconBox,
    childLinks: [
      {
        link: '/products',
        label: 'Products'
      },
      {
        link: '/bundles',
        label: 'Bundles'
      },
      {
        link: '/categories',
        label: 'Categories',
        disableForEditor: true
      }
    ]
  },
  {
    link: '/content',
    label: 'Content',
    icon: IconFilePencil,
    childLinks: [
      {
        link: '/content',
        label: 'Content'
      },
      {
        link: '/content-categories',
        label: 'Categories',
        disableForEditor: true
      },
      {
        link: '/content-types',
        label: 'Content types',
        disableForEditor: true
      }
    ]
  },
  {
    link: '/pages',
    label: 'Pages',
    icon: IconAppWindow,
    disableForEditor: true
  },
  {
    link: '/customers',
    label: 'Customers',
    icon: IconUsers,
    disableForEditor: true
  },
  {
    link: '/influencers',
    label: 'Influencers',
    icon: IconUserPin,
    disableForEditor: true,
    childLinks: [
      {
        link: '/influencers',
        label: 'Influencers',
        disableForEditor: true
      },
      {
        link: '/invitations',
        label: 'Invitations',
        disableForEditor: true
      }
    ]
  },
  {
    link: '/tips',
    label: 'Tips',
    icon: IconInfoSquare
  },
  {
    link: '/conversations',
    label: 'Conversations',
    icon: IconMessage
  },
  {
    link: '/feedbacks',
    label: 'Feedbacks',
    icon: IconMessageReport,
    disableForEditor: true
  },
  {
    link: '/team-members',
    label: 'Team members',
    icon: IconFriends,
    disableForEditor: true
  },
  {
    link: '/subscribers',
    label: 'Subscribers',
    icon: IconUsersPlus,
    disableForEditor: true
  },
  {
    link: '/system/users',
    label: 'System',
    icon: IconAdjustmentsAlt,
    childLinks: [
      {
        link: '/system/users',
        label: 'Users'
      },
      {
        link: '/system/files',
        label: 'Files'
      },
      {
        link: '/system/settings',
        label: 'Settings'
      }
    ],
    disableForEditor: true
  }
];
