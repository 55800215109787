import { Center, ScrollArea, Stack, useMantineTheme } from '@mantine/core';
import cx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useAuthStore from '../../store/auth/auth.store';
import { navbarLinks } from '../nav-links/admin';
import classes from './DefaultSidebar.module.css';

export function DefaultSidebar() {
  const theme = useMantineTheme();
  const { userInfo } = useAuthStore();

  const [active, setActive] = useState('Dashboard');
  const [childActive, setChildActive] = useState('');

  return (
    <div className={classes.navbar}>
      <ScrollArea h="100%" className={classes.pad} type="auto">
        <div className={classes.links}>
          {navbarLinks.map((navbarLink) => (
            <Stack key={navbarLink.label} gap={0} style={{ borderRadius: theme.radius.sm, overflow: 'hidden' }}>
              <Link
                to={navbarLink.link}
                className={cx(classes.link, {
                  [classes.linkActive]: navbarLink.label === active,
                  [classes.linkDisabled]: userInfo?.role !== 'admin' && navbarLink.disableForEditor
                })}
                onClick={() => {
                  setActive(navbarLink.label);
                  setChildActive(
                    (navbarLink?.childLinks || []).length > 0 ? (navbarLink?.childLinks || [])[0].label : ''
                  );
                }}>
                <navbarLink.icon className={classes.linkIcon} size={20} />
                <span>{navbarLink.label}</span>
              </Link>
              {navbarLink.label === active &&
                (navbarLink.childLinks || []).map((childLink) => (
                  <Link
                    key={childLink.label}
                    to={childLink.link}
                    className={cx(classes.link, {
                      [classes.childLinkActive]: childLink.label === childActive,
                      [classes.linkDisabled]: userInfo?.role !== 'admin' && childLink.disableForEditor
                    })}
                    onClick={() => setChildActive(childLink.label)}>
                    <Center style={{ width: 24, marginRight: theme.spacing.sm }}>
                      <div className={classes.childLinkDot} />
                    </Center>
                    <span>{childLink.label}</span>
                  </Link>
                ))}
            </Stack>
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}
