import { Location } from 'react-router-dom';
import { create } from 'zustand';
import { fixedRoutes } from '../../router/routes';
import { Route } from '../../types/router';
import { logger } from '../middlewares/logger.middleware';

interface IRouterState {
  fixedRoutes: Route[];
  asyncRoutes: Route[];
  location: {
    last: Partial<Location>;
    current: Partial<Location>;
  };
}

interface IRouterActions {
  addRoute: (route: Route) => void;
  addRoutes: (routes: Route[]) => void;
  pushLocation: (location: Location) => void;
  replaceLocation: (location: Location) => void;
}

export const useRouterStore = create<IRouterState & IRouterActions>()(
  logger((set) => ({
    fixedRoutes: fixedRoutes,
    asyncRoutes: [],
    location: {
      last: {},
      current: {}
    },
    addRoute: (route: Route) => {
      set((state) => ({ asyncRoutes: [...state.asyncRoutes, route] }));
    },
    addRoutes: (routes: Route[]) => {
      set((state) => ({ asyncRoutes: [...state.asyncRoutes, ...routes] }));
    },
    pushLocation: (location: Location) => {
      set((state) => ({
        location: {
          last: state.location.current,
          current: location
        }
      }));
    },
    replaceLocation: (location: Location) => {
      set(() => ({
        location: {
          last: {},
          current: location
        }
      }));
    }
  }))
);
