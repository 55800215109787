import { AppShell, useMantineColorScheme } from '@mantine/core';

import LoadingBox from '@/components/LoadingBox';
import useAuthStore from '@/store/auth/auth.store';
import { Outlet } from 'react-router-dom';
import useSidebarStore from '../store/sidebar/sidebar.store';
import { DefaultHeader } from './DefaultHeader';
import { DefaultSidebar } from './DefaultSidebar/DefaultSidebar';

const Layout = () => {
  const { colorScheme } = useMantineColorScheme();
  const { collapsed } = useSidebarStore();
  const { retrying } = useAuthStore();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 200, breakpoint: 'sm', collapsed: { mobile: collapsed, desktop: collapsed } }}
      padding="md">
      <AppShell.Header>
        <DefaultHeader />
      </AppShell.Header>
      <AppShell.Navbar>
        <DefaultSidebar />
      </AppShell.Navbar>

      <AppShell.Main
        style={(theme) => ({
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          maxWidth: '100%'
        })}>
        {retrying && <LoadingBox>Loading...</LoadingBox>}
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};

export default Layout;
