import React from 'react';

export type AnyRecord = Record<string | number, any>;
export type Assign<T1 = {}, T2 = {}> = Omit<T1, keyof T2> & T2;

export interface UIComponent<T extends keyof JSX.IntrinsicElements | React.ComponentType, P extends object = {}>
  extends React.ForwardRefExoticComponent<Assign<Omit<AnyRecord, 'as'>, Assign<React.ComponentPropsWithRef<T>, P>>> {
  <As extends keyof JSX.IntrinsicElements | React.ComponentType = T>(
    props: Assign<
      Omit<AnyRecord, 'as'>,
      Assign<React.ComponentPropsWithRef<As>, P> & {
        as?: As;
      }
    >
  ): React.ReactElement | null;
}

export type Incl<T, K extends keyof T> = Pick<T, Extract<keyof T, K>>;

export interface SelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export enum EntityPropertyType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  ARRAY = 'array',
  OPTION = 'option',
  COLLECTION = 'collection',
  PLACEHOLDER = 'placeholder'
}

export interface EntityPropertySpec {
  label: string;
  isShowInTable?: boolean;
  isSortable?: boolean;
  [key: string]: any;
}

export type EntityPropertySchema<T = unknown> = Record<string | keyof T, EntityPropertySpec>;

export type FilterPropertyTypes = Record<string, EntityPropertyType>;

export type FilterPropertyValueSelection = Record<string, SelectOption[]>;

export enum OrderType {
  ASC = 'asc',
  DESC = 'desc'
}

export interface IFileSelection {
  file_id?: string;
  file_url: string;
  file_name?: string;
}

export interface ISortCriteria {
  property: string | null;
  order: OrderType | null;
}

export interface IFilterCriteria {
  property: string | null;
  value: string | null;
}

export interface IFilterSelection {
  property: string;
  propertyText: string;
  type: EntityPropertyType;
  possibleValues: SelectOption[];
}
