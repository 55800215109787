import { create } from 'zustand';
import { logger } from '../middlewares/logger.middleware';

interface ISidebarState {
  collapsed: boolean;
  setSidebarCollapsed: (status: boolean) => void;
}

const useSidebarStore = create<ISidebarState>()(
  logger((set) => ({
    collapsed: false,
    setSidebarCollapsed: (status) => set(() => ({ collapsed: status }))
  }))
);

export default useSidebarStore;
