export const TOKEN_NAME = 'app-auth$token';

export const token = {
  get: () => {
    const session = localStorage.getItem(TOKEN_NAME);
    return session ? JSON.parse(session) : null;
  },
  set: (value?: string | null) => {
    if (value) {
      localStorage.setItem(TOKEN_NAME, value);
    } else {
      localStorage.removeItem(TOKEN_NAME);
    }
  }
};
