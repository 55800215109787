export async function retryPromise<T>(options: { promise: () => Promise<T>; retryCount: number }): Promise<T> {
  let lastError: any;
  for (let i = 0; i < options.retryCount; i++) {
    try {
      const response = await options.promise();
      return response;
    } catch (err) {
      if (i === options.retryCount - 1) {
        lastError = err;
      } else {
        const delay = Math.pow(2, i) * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  }

  throw lastError;
}
