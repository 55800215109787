import { EntityPropertyType, SelectOption } from '../utils/types';
import { IApiPaginationResponse } from './api';

export const PAGE_SIZE_SELECTIONS: SelectOption[] = [
  {
    value: '5',
    label: '5'
  },
  {
    value: '10',
    label: '10'
  },
  {
    value: '25',
    label: '25'
  },
  {
    value: '50',
    label: '50'
  }
];

export const COMMON_PROPERTIES = {
  id: {
    label: 'Id',
    type: EntityPropertyType.NUMBER
  }
};

export const DEFAULT_LIST_RESPONSE: IApiPaginationResponse = {
  records: [],
  page: 1,
  limit: 20,
  total: 0,
  pages: 0
};

export enum CountryEnum {
  GB = 'gb',
  US = 'us'
}

export enum LibraryContentType {
  CONDITION = 0,
  TREATMENT = 1,
  ARTICLE = 3,
  SYMPTOM = 4
}

export const LibraryContentTypeTranslation = {
  '0': 'Condition',
  '1': 'Treatment',
  '3': 'Article',
  '4': 'Symptom'
};

export enum RoleEnum {
  ADMIN = 'admin',
  EDITOR = 'editor'
}

export enum SettingGroupEnum {
  DOSAGE_FORM = 'dosage_form',
  LEGAL_CATEGORY = 'legal_category',
  FEEDBACK_EMAILS = 'feedback_emails',
  TIP_CONSULTATION = 'tip_consulation',
  INVESTOR_EMAILS = 'investor_emails',
  REMOVED = 'removed'
}

export enum ProductTypeEnum {
  BUNDLE = 1,
  PRODUCT = 0
}

export const ProductTypeTranslation = {
  '0': 'Product',
  '1': 'Bundle'
};

export type SupportedCurrency = 'GBP' | 'USD';

export const CurrencySymbolDefinitions = {
  ['GBP' as SupportedCurrency]: '£',
  ['USD' as SupportedCurrency]: '$'
};

export type SupportedCountry = 'gb' | 'us';

export const CountryCurrencyDefinitions = {
  ['gb' as SupportedCountry]: 'GBP',
  ['us' as SupportedCountry]: 'USD'
};

export const CountryCurrencySymbolDefinitions = {
  ['gb' as SupportedCountry]: '£',
  ['us' as SupportedCountry]: '$'
};

export enum OrderStatusEnum {
  AWAITING = 'awaiting',
  PLACED = 'placed',
  PROCESSED = 'processed',
  SHIPPED = 'shipped',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed'
}

export enum PaymentStatusEnum {
  PENDING = 'pending',
  PAID = 'paid',
  REFUNDED = 'refunded',
  PARTIALLY_REFUNDED = 'partially_refunded'
}

export enum FulfilmentStatusEnum {
  UNFULFILLED = 'unfulfilled',
  FULFILLED = 'fulfilled',
  PARTIALLY_FULFILLED = 'partially_fulfilled'
}

export enum SectionContentTypeEnum {
  MARKDOWN = 'markdown',
  SINGLE_IMAGE = 'single_image',
  MULTIPLE_IMAGES = 'multiple_images',
  VIDEO = 'video',
  SINGLE_PRODUCT = 'single_product',
  MULTIPLE_PRODUCTS = 'multiple_products'
}

export const INFLUENCER_SECTION_TITLES: Array<{ title: string; color: string }> = [
  {
    title: 'My morning route',
    color: '#ffefdd'
  },
  {
    title: 'My health hacks',
    color: '#ffe7da'
  },
  {
    title: 'What I like to eat',
    color: '#ffdfd8'
  },
  {
    title: 'What I avoid to eat',
    color: '#ffdce2'
  },
  {
    title: 'My exercise plan',
    color: '#b0b0b0'
  },
  {
    title: 'My skincare routine',
    color: '#dde5f0'
  },
  {
    title: 'My go-to health products',
    color: '#d3eef4'
  },
  {
    title: 'My typical gym session',
    color: '#bff2f2'
  },
  {
    title: 'My hydration fix',
    color: '#bff3ec'
  },
  {
    title: 'My wellness tips',
    color: '#e9f0e2'
  }
];

export enum ProductFulfilmentProviderEnum {
  DAYLEWIS = 'daylewis',
  TIP = 'theindependentpharmacy',
  HEALTHWAREHOUSE = 'healthwarehouse'
}

export enum ProductClassificationTypeEnum {
  OTC = 'otc',
  PMED = 'pmed'
}

export const ActivityLogActionTranslation: Record<string, string> = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
  modify: 'modified',
  upload: 'uploaded'
} as const;

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_INDEX = 1;
